const recycleBin = {
  title: 'Papelera de reciclaje',
  searchHeader: 'Filtrar Resultados',
  startDate: 'Mostrar elementos reciclados entre',
  endDate: 'y',
  recordType: 'Tipo de registro',
  recordTypes: {
    0: '',
    1: 'Activos',
    2: 'Categorías',
    3: 'Certificaciones',
    4: 'Classes',
    5: 'Contratistas',
    6: 'Centros de costo',
    7: 'Códigos de costo',
    8: 'Contar entradas',
    9: 'Tableros personalizados',
    10: 'Reportes personalizados',
    11: 'Clientes',
    12: 'Departamentos',
    13: 'Tipos de empleados',
    14: 'Empleado',
    15: 'Tarjetas de combustible',
    16: 'Equipos',
    17: 'Ubicaciones',
    18: 'Fabricantes',
    19: 'Maestros',
    20: 'Orden de compra',
    21: 'Líneas de hoja de tarifas',
    22: 'Hojas de tarifas',
    23: 'Clases de servicio',
    24: 'Solicitudes de servicio',
    25: 'Métodos de envío',
    26: 'Códigos de estado',
    27: 'Transferir',
    28: 'Unidades de medida',
    29: 'Vendedores',
    30: 'Órdenes de trabajo',
    36: 'Plantillas'
  },
  actionHeader: {
    0: {
      title: 'Ver articulo',
      icon: 'fas fa-edit'
    },
    1: {
      title: 'Restaurar',
      icon: ''
    }
  },
  detail: {
    title: 'Reciclar detalle',
    labels: {
      0: 'Número',
      1: 'Tipo',
      2: 'Ubicación de stock',
      3: 'Creado en',
      4: 'Creado por',
      5: 'Eliminado en',
      6: 'Eliminado por'
    }
  },
  tableHeader: {
    desktop: [
      {
        label: '',
        key: 'action'
      },
      {
        label: 'Eliminado',
        key: 'DeletedOn',
        type: Date,
        sortable: true
      },
      {
        label: 'Tipo',
        key: 'RecordType',
        sortable: true
      },
      {
        label: 'Descripción',
        key: 'Description',
        type: String,
        sortable: true
      },
      {
        label: 'Número de registro',
        key: 'DeletedRecordNumber',
        type: String,
        sortable: true
      }
    ],
    mobile: [
      {
        label: '',
        key: 'action'
      },
      {
        label: 'Eliminado',
        key: 'DeletedOn',
        type: Date,
        sortable: true
      },
      {
        label: 'Info',
        data: [
          {
            label: 'Tipo',
            key: 'RecordType',
            sortable: true
          },
          {
            label: 'Descripción',
            key: 'Description',
            type: String,
            sortable: true
          }
        ]
      },
      {
        label: 'Número de registro',
        key: 'DeletedRecordNumber',
        type: String,
        sortable: true
      }
    ],
    dropdownActions: [
      {
        title: 'Reintegrar',
        actionName: 'reinstate',
        permissions: []
      }
    ]
  }
}

export default recycleBin
